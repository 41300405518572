<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formPacient">
        <div class="form-row col-md-12 mt-12">
          <div class="form-group col-md-12">
            <label for="nome">{{ $t('mesages.forms.name_man') }} do {{ $t('resources.singular.pacient') }}:</label>
            <b-form-input id="nome" type="text" class="form-control"
             :placeholder="$t('mesages.forms.name_man')+' '+$t('resources.singular.pacient')"
             v-model="pacient.nome"
             :class="{ 'is-invalid': submit && $v.pacient.nome.$error }"
            >
            </b-form-input>
            <div v-if="submit && $v.pacient.nome.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.nome.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="cpf" >CPF do paciente:</label>
            <b-form-input id="cpf" type="tel" class="form-control" v-mask="'###.###.###-##'" v-model="pacient.cpf"
             placeholder="Ex: 12934567889"
             :class="{ 'is-invalid': submit && $v.pacient.cpf.$error }"
            ></b-form-input>
            <div v-if="submit && $v.pacient.cpf.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.cpf.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="contact">Contato telefônico:</label>
            <input id="contact" type="tel" class="form-control" v-mask="'## ##### ####'" v-model="pacient.contact" placeholder="Ex: 12934567889">
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="mae">{{ $t('mesages.forms.mother_name') }}:</label>
            <b-form-input type="text" class="form-control" v-model="pacient.mae" 
             :placeholder="$t('mesages.forms.mother_name')"
             :class="{ 'is-invalid': submit && $v.pacient.mae.$error }"
            ></b-form-input>
            <div v-if="submit && $v.pacient.mae.$error" class="invalid-tooltip">
              <span v-if="!$v.pacient.mae.required">{{ $t('mesages.forms.required')}}!</span>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="pai">{{ $t('mesages.forms.father_name') }}:</label>
            <b-form-input type="text" class="form-control" v-model="pacient.pai"
             :placeholder="$t('mesages.forms.father_name')"
            ></b-form-input>
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <!-- Responsável pelo Paciente -->
          <div class="form-group col-md-4">
            <label for="responsable_id">Nome do responsável:</label>
            <multiselect v-model="responsible" :options="listResponsibles" :multiple="false"
             placeholder="Selecione"
             track-by="id"
             label="name"
             :loading="isLoading"
             selectLabel='Pressione ENTER para selecionar'
             deselectLabel='Pressione ENTER para remover'
             @input="onSelectResponsible"
             :class="(submit && $v.pacient.responsable_id.$error) ? 'is-invalid' : ''"
            >
              <template slot="afterList">
                <div class="insert-responsible">
                  <b-button variant="outline-light" class="btn-block" v-b-modal.modal-responsible>
                    <i class="ri-user-add-fill align-middle mr-2"></i> Adicionar
                  </b-button>
                </div>
              </template>
            </multiselect>
          </div>
          <div v-if="submit && $v.pacient.responsable_id.$error" class="invalid-feedback">
            <span v-if="!$v.pacient.responsable_id.required">Responsável inválido</span>
          </div>
          <!-- Fim Responsável pelo Paciente-->
          <div class="form-group col-md-4">
            <label for="agreement_id">Convênio:</label>
            <select
              v-model="pacient.agreement_id"
              class="form-control"
              id="agreement_id"
            >
              <option value="-1" selected="selected">Selecione o convênio</option>
              <option
                v-for="agreement in listAgreements"
                :key="agreement.id"
                :value="agreement.id"
              >
                {{ agreement.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label for="is_private">Particular?</label>
            <input type="checkbox" class="form-control" v-model="pacient.is_private" placeholder="Sim/Não">
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <button type="submit" class="btn btn-primary" style="margin-right: 20px;">Cadastrar</button>
        </div>
      </form>
    </div>
    <!-- Start Modal View -->
    <b-modal
      id="modal-responsible"
      ref="modal-responsible"
      @ok="submitResponsible"
      no-close-on-backdrop
      title="Incluir Responsável"
      title-class="font-18"
      size="lg"
      hide-footer
    >
      <template #default="">
        <insertModal 
         :currentResponsible="responsible"
         :currentEmpresa="currentEmpresa"
         :ok="submitResponsible"
        />
      </template>
    </b-modal>
    <!-- End Modal View -->
  </div>
</template>

<script>
console.clear();
import { notificationMethods } from "@/state/helpers";
import {http} from '@/helpers/easyindustriaapi/config';
import { required, integer } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import insertModal from "../responsible/insertModal";
import Swal from "sweetalert2";

export default {
  props: {
    currentEmpresa: {type: Object,required: true},
    listAgreements: { required: true },
    listResponsibles: { required: true }
  },
  components: { Multiselect, insertModal },
  data() {
    return {
      loader: {
        get: false,
      },
      isLoading: false,
      items: [{
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Cadastros",
          href: "/cadastros",
          active: true,
        },
        {
          text: "Pacientes",
          href: "/cadastros/pacientes",
          active: true,
        }
      ],
      titleBody: "Inserindo",
      inserindo: true,
      submit: false,
      pacient: {
        nome: null,
        cpf: null,
        agreement_id: null, // convênio (Unimed, SulAmerica)
        pai: null,
        mae: null,
        responsable_id: null,
        contact: null,
      },
      search: '',
      responsible: {},
    };
  },
  validations: {
    pacient: {
      nome: { required },
      cpf: { required },
      mae: { required },
      responsable_id: { required, integer },
    }
  },
  mounted() {
    this.$emit("newTitle", "Incluir Paciente");
    this.pacient.empresa_id = this.currentEmpresa.id;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    filtered() {
      return this.listResponsibles.filter((responsible) =>
        responsible.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submit = true;
      this.$v.$touch();

      if (this.$v.pacient.$error) {
        if (!this.$v.pacient.responsable_id.required)
          this.makeToast("danger", "Responsável é obrigatório!")
          else        
            this.makeToast("danger", "Erro de preenchimento!");
      } else {
        this.setPost();
      }
    },
    focusMyElement() {
      this.$refs.focusThis.focus()
    },
    setPost() {  
      if(this.pacient.is_private != 1){
        this.pacient.is_private =0 ;
      }
      this.$emit("doPost", this.pacient);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
    onSearch(query) {
      this.isLoading = true;
      this.search = query;
      this.isLoading = false;
    },
    submitResponsible() {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Gravando dados do Responsável...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      this.responsible.empresa_id = this.currentEmpresa.id;
      const promise = http.post('/responsible?empresa_id=' + this.currentEmpresa.id, this.responsible);

      return promise.then((response) => {
        console.log(response.data);
        this.makeToast('success', 'Registro incluído');
        this.onSelectResponsible(response.data[response.data.length - 1]);
        this.$emit("setResponsibles", response.data);
        this.$root.$emit('bv::hide::modal', 'modal-responsible', '#btnOk');
      })
      .catch((error) => {
        let listErros = error.response;

        for (let index = 0; index < listErros.length; index++) {
          this.makeToast('danger', listErros[index]);
        }
      }).finally(() => {
        Swal.close(); // Fecha o loader
      });
    },
    onSelectResponsible(selectedResponsible) {
      // Seta apenas o ID do responsável no model pacient.responsable_id
      this.pacient.responsable_id = selectedResponsible ? selectedResponsible.id : null;
    }
  },
};
</script>