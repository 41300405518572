<script>
console.clear();

import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
// import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    currentPacient: { type: Object, required: true },
    listAgreements: { type: Array },
    listResponsibles: { type: Array },
  },
  components: {},
  data() {
    return {
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Parâmetros",
          href: "/",
          active: true,
        },
        {
          text: "Plano de Contas",
          href: "/parametros/plano",
          active: true,
        },
      ],
      titleBody: "Inserindo",
      inserindo: true,
      submitted: false,
      pacient: {
        nome: "",
        cpf: "",
        agreement_id: -1, // convêcnio (Unimed, SulAmerica)
        pai: "",
        mae: "",
        responsable_id: -1,
        contact: "",
      },
    };
  },
  validations() {
    return {
      pacitent: {
        nome: { required },
        cpf: { required },
        agreement_id: { required },
        pai: { required },
        mae: { required },
        responsable_id: { required },
        contact: { required },
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Paciente");
    this.pacient.empresa_id = this.currentEmpresa.id;
    this.pacient = this.currentPacient;
    console.log("Mostrando array de Resposiveis e planos");
    console.log(this.listAgreements);
    console.log(this.listResponsibles);
    console.log("mostrano paacient")
    console.log(this.pacient)
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    getCidadeId(objects, key) {
      return objects.map(function (object) {
        return object[key];
      });
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.pacient.$error) {
      //   this.makeToast("danger", "Erro de validação!");
      // } else {
      //   this.setPost();
      // }

      this.setPost();
    },
    setPost() {
      console.log("monsstraano paaciente objjeto")
      console.log(this.pacient)
      this.$emit("doPut", this.pacient);
    },
    back() {
      this.$emit("back");
    },
    changeLoader(value) {
      this.loader.get = value;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formPacient">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="nome">NOME DO PACIENTE:</label>
            <input
              id="nome"
              type="text"
              class="form-control"
              v-model="pacient.nome"
              placeholder="Nome do Paciente"
            />
          </div>

          <div class="form-group col-md-4">
            <label for="cpf">CPF DO PACIENTE:</label>
            <input
              id="cpf"
              type="tel"
              class="form-control"
              v-mask="'###.###.###-##'"
              v-model="pacient.cpf"
              placeholder="Ex: 12934567889"
            />
          </div>

          <div class="form-group col-md-4">
            <label for="contact">CONTATO TELEFÔNICO:</label>
            <input
              id="contact"
              type="tel"
              class="form-control"
              v-mask="'## ##### ####'"
              v-model="pacient.contact"
              placeholder="Ex: 12934567889"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-6">
            <label for="mae">NOME DA MÃE:</label>
            <input
              type="text"
              class="form-control"
              v-model="pacient.mae"
              placeholder="Nome do mãe"
              required
            />
          </div>

          <div class="form-group col-md-6">
            <label for="pai">NOME DO PAI:</label>
            <input
              type="text"
              class="form-control"
              v-model="pacient.pai"
              placeholder="Nome do pai"
            />
          </div>
        </div>

        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="responsable_id">NOME DO RESPONSÁVEL:</label>
            <select
              v-model="pacient.responsable_id"
              class="form-control"
              id="responsible_id"
            >
              <option value="0">Selecione o Responsável</option>
              <option
                v-for="responsible in listResponsibles"
                :key="responsible.id"
                :value="responsible.id"
                v-bind:selected="
                  pacient.responsible_id == responsible.id ? 'selected' : ''
                "
              >
                {{ responsible.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-4">
            <label for="agreement_id">CONVÊNIO:</label>
            <select
              v-model="pacient.agreement_id"
              class="form-control"
              id="agreement_id"
            >
              <option value="-1">Selecione o convênio</option>
              <option
                v-for="agreement in listAgreements"
                :key="agreement.id"
                :value="agreement.id"
                v-bind:selected="
                  pacient.agreement_id == agreement.id ? 'selected' : ''
                "
              >
                {{ agreement.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-2">
            <label for="is_private">PARTICULAR?</label>
            <input
              type="checkbox"
              class="form-control"
              v-model="pacient.is_private"
              placeholder="Sim/Não"
            />
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 20px"
          >
            Alterar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>