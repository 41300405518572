<template>
  <div class="row">
    <div class="col-md-12">
      <form name="formResponsible" @submit.stop.prevent="formSubmit" ref="formResponsible">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-8">
            <label for="name">Nome do responsável:</label>
            <b-form-input ref="name" class="form-control" placeholder="Digite o nome completo do responsável"
             v-model="responsavel.name"
             :class="{ 'is-invalid': submit && $v.responsavel.name.$error }"
            >
            </b-form-input>
            <div v-if="submit && $v.responsavel.name.$error" class="invalid-tooltip">
              <span v-if="!$v.responsavel.name.required">Por favor, informe o Nome do Responsável!</span>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="cpf">CPF do responsável:</label>
            <b-form-input type="text" class="form-control" v-mask="'###.###.###-##'" placeholder="###.###.###-##"
             v-model="responsavel.cpf"
             :class="{ 'is-invalid': submit && $v.responsavel.cpf.$error }"
            >
            </b-form-input>
            <div v-if="submit && $v.responsavel.cpf.$error" class="invalid-feedback">
              <span v-if="!$v.responsavel.cpf.minValue">O CPF tem 11 dígitos!</span>
            </div>
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-7">
            <label for="rua">Rua:</label>
            <input type="text" class="form-control" maxlength="40" placeholder="Travessa do caiano" v-model="responsavel.rua">
          </div>
          <div class="form-group col-md-5">
            <label for="bairro">Bairro:</label>
            <input type="text" class="form-control" maxlength="40" placeholder="Ex: Centro" v-model="responsavel.bairro">
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-3">
            <label for="numero">Nº:</label>
            <input type="text" class="form-control" maxlength="5" placeholder="Ex: 65B" v-model="responsavel.numero">
          </div>
          <div class="form-group col-md-4">
            <label for="celular">Contato telefônico:</label>
            <input type="phone" class="form-control" v-mask="'## #####-####'" placeholder="Ex: 87999658729" v-model="responsavel.celular">
          </div>
          <div class="form-group col-md-5">
            <label for="email">E-mail:</label>
            <input type="email" class="form-control" placeholder="Informe um e-mail válido"
             v-model="responsavel.email"
             :class="{ 'is-invalid': submit && $v.responsavel.email.$error }"
            >
            <div v-if="submit && $v.responsavel.email.$error" class="invalid-feedback">
              <span v-if="!$v.responsavel.email.email">O e-mail informado não é válido!</span>
            </div>
          </div>
        </div>
        <div class="modal-footer mt-4">
            <b-button id="btnOk" type="submit" size="sm" variant="success">Gravar</b-button>
            <b-button id="btnCancel" type="button" size="sm" variant="danger" @click="hideModal()">Cancelar</b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { notificationMethods } from "@/state/helpers";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  props: {
    currentEmpresa: {type: Object, required: true},
    currentResponsible: { type: Object },
    ok: Function
  },
  data() {
    return {
      items: [{
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Responsável",
          href: "/",
          active: true,
        },
        {
          text: "Incluir Responsável",
          href: "/cadastros/responsaveis",
          active: true,
        }
      ],
      titleBody: "Inserindo",
      responsavel: {
        id: null,
        name: null,
        cpf: "",
        rg: "",
        email: "",
        celular: "",
        cep: "",
        rua: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: null,
        cidade_nome: "",
        uf: null,
        uf_nome: "",
        empresa_id: null,
        status: true,
      },
      submit: false,
    };
  },
  validations: {
    responsavel: {
      name: { required },
      cpf: { minLength: minLength(14) },
      email: { email },
    },
  },
  mounted() {
    this.responsavel = this.currentResponsible;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...notificationMethods,
    setPost() {
      this.currentResponsible = this.responsavel;
      this.$emit("ok", this.responsavel);
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.responsavel.$error) {
        this.makeToast("danger", "Erro de preenchimento!");
      } else {
        this.ok();
      }
    },
    back() {
      this.$emit("back");
    },
    clearForm() {
      this.responsavel.name = "";
      this.responsavel.cpf = "";
      this.responsavel.email = "";
      this.responsavel.celular = "";
      this.responsavel.cep = "";
      this.responsavel.rua = "";
      this.responsavel.numero = "";
      this.responsavel.bairro = "";
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-responsible', '#btnCancel');
      this.clearForm();
    },
  }
};
</script>